import * as React from "react"
import Link from "../components/link/link"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Layout from "../components/layout/layout"
import Seo from "../components/seo/seo"
import { HeadFC } from "gatsby"

const NotFoundPage: React.FC = () => (
  <Layout>
    <Container>
      <Row>
        <Col className="min-vh-100 d-flex flex-column justify-content-center">
          <h1 className="text-blue700">404 Page Not Found.</h1>
          <p>
            It seems like the page you’re looking for doesn’t exist. Please
            visit our <Link to="/">homepage</Link> or{" "}
            <Link to="contact-us">contact us</Link> for more information.
          </p>
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default NotFoundPage

export const Head: HeadFC = () => <Seo altTitle="404: Not Found" />
